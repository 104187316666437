<script setup lang="ts">
import { batch } from '@villus/batch'
import { useClient } from 'villus'
import { GetFlashMessagesDocument } from './app.generated'
import '@vuepic/vue-datepicker/dist/main.css'

const { isAuthenticated } = useAuth()
const cachePlugin = useGqlCache()
const globalErrorHandler = useGlobalRequestErrorHandler()
const { startWatching } = useWebsocket()

useClient({
  use: [
    globalErrorHandler,
    cachePlugin,
    batch({ maxOperationCount: 20 }),
  ],
  url: '/graphql/batch',
})

const toast = useToast()

// TODO: get proper copy from marketing
useHead({
  title: 'Pulse by Sleuth',
  meta: [
    {
      name: 'description',
      content: 'Pulse is a review management tool for developers',
    },
  ],
})
const { fetchCurrentUser, isBeingImpersonated, user } = useCurrentUser()

watch(isAuthenticated, async (value) => {
  if (value) {
    await fetchCurrentUser({ cachePolicy: 'network-only' })
    // there has to be a user at this point
    if (user.value) {
      startWatching(user.value.id)
    }
    else {
      console.error('No user found after authentication')
    }
    useIsAppUpToDate()
  }
}, { immediate: true })

watch(isBeingImpersonated, (newValue, oldValue) => {
  if (newValue === true && oldValue === false) {
    // reload all tabs when impersonating to avoid GID errors and whatnot
    window.location.replace('/')
  }
}, { immediate: true })

const { onData } = useQuery({
  query: GetFlashMessagesDocument,
  tags: ['flashMessages'],
  paused: () => !isAuthenticated.value,
})

onData((data) => {
  if (data?.flashMessages) {
    data.flashMessages.forEach((message, id) => {
      toast.add({
        id: id.toString(),
        description: message.message,
        timeout: message.type === 'ERROR' ? 0 : 5000,
      })
    })
  }
})
</script>

<template>
  <div class="flex h-screen w-screen flex-col bg-white text-base dark:bg-gray-950 dark:text-white">
    <NuxtLayout>
      <NuxtPage />
      <UNotifications />
    </NuxtLayout>
  </div>
</template>

<style>
h1 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 600;
}
h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 600;
}
h3 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 600;
}
h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}
h5 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}
h6 {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 600;
}

/* Default <Transition> classes */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@media print {
  .dark {
    color-scheme: light !important;
  }
}

details > summary::-webkit-details-marker {
  display: none;
}

:root {
  --dp-border-radius: 6px;
  --dp-font-size: inherit;
  --dp-font-family: inherit;
  --dp-cell-size: 32px;
}
.dp__theme_light, .dp__theme_dark {
  --dp-border-color: transparent;
  --dp-primary-color: rgb(var(--color-primary-600));
}
</style>
