import { differenceInHours } from 'date-fns'

const DEPLOY_FRONTEND_TASK_DURATION_IN_HOURS = 12

export default function () {
  const { onData, execute } = useQuery({ query: GetReleaseCommitDocument })

  const runtimeConfig = useRuntimeConfig()
  const toast = useToast()
  const isShowingUpdateToast = ref(false)
  const clientLoadedAt = new Date().getTime()

  async function fetchData() {
    await execute({ cachePolicy: 'network-only' })
  }

  const { pause, resume } = useTimeoutPoll(fetchData, 30_000)
  resume() // start polling right away

  const visibility = useDocumentVisibility()
  watchDebounced(visibility, (current, previous) => {
    if (current === 'visible' && previous === 'hidden' && !isShowingUpdateToast.value)
      resume()
    else
      pause()
  }, { debounce: 15_000, maxWait: 30_000 })

  onData(({ releaseCommit }) => {
    const latestReleaseCommitAt = new Date().getTime()

    const timeBetweenClientAndServerRelease = differenceInHours(latestReleaseCommitAt, clientLoadedAt)
    if (timeBetweenClientAndServerRelease < DEPLOY_FRONTEND_TASK_DURATION_IN_HOURS)
      return

    // is backend release commit different from the client one
    if (!runtimeConfig.public.releaseCommitSha.startsWith(releaseCommit)) {
      pause()
      isShowingUpdateToast.value = true
      toast.add({
        id: 'new-client-available',
        description: 'There is a new version of app available. Please refresh your browser to get the latest updates.',
        timeout: 0,
        actions: [{
          label: 'Refresh',
          icon: 'light-arrow-rotate-right',
          click: () => window.location.reload(),
        }],
        callback: () => isShowingUpdateToast.value = false,
      })
    }
  })
}
