import { useWebSocket } from '@vueuse/core'

const SECURE_HOSTNAMES = ['app.staging.pulse.sleuth.io', 'app.pulse.sleuth.io']

export default defineNuxtPlugin(() => {
  const protocol = SECURE_HOSTNAMES.includes(location.hostname) ? 'wss' : 'ws'

  let lastKnownId = ''

  const ws = useWebSocket(`${protocol}://${location.hostname}/_api/stream/`, {
    immediate: false,
    // Use this to keep websocket connection alive if needed
    heartbeat: {
      interval: 15000,
      message: 'ping',
      pongTimeout: 5000,
    },
    autoReconnect: {
      retries: 120,
      delay: 1000,
      onFailed() {
        console.error('Failed to connect WebSocket after 120 retries')
      },
    },
    onConnected(ws) {
      ws.send(
        JSON.stringify({
          type: 'event_subscription_request',
          since: lastKnownId,
        }),
      )
    },
    onMessage(ws, event) {
      const data = JSON.parse(event.data)
      if (data.id)
        lastKnownId = data.id
    },
  })

  return {
    provide: {
      ws,
    },
  }
})
