import { default as delete_45accounteUrTp9kLUkMeta } from "/home/circleci/sleuth/frontend/pages/delete-account.vue?macro=true";
import { default as index_46generated1Cf5xau5lOMeta } from "/home/circleci/sleuth/frontend/pages/index.generated.ts?macro=true";
import { default as indexh7SRb3nn6mMeta } from "/home/circleci/sleuth/frontend/pages/index.vue?macro=true";
import { default as set_45passwordAzscWNBrFJMeta } from "/home/circleci/sleuth/frontend/pages/reset-password/[uid]/set-password.vue?macro=true";
import { default as indexvvru9fc4LzMeta } from "/home/circleci/sleuth/frontend/pages/reset-password/index.vue?macro=true";
import { default as request_45successNB3aNb4FiRMeta } from "/home/circleci/sleuth/frontend/pages/reset-password/request-success.vue?macro=true";
import { default as reset_45successVJaqBklhrAMeta } from "/home/circleci/sleuth/frontend/pages/reset-password/reset-success.vue?macro=true";
import { default as settings_46generatedvrt7cwwJrVMeta } from "/home/circleci/sleuth/frontend/pages/settings.generated.ts?macro=true";
import { default as organization_46generatedDt8CUuqlwWMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization.generated.ts?macro=true";
import { default as aioz7O4ZcIpkMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/ai.vue?macro=true";
import { default as authenticationE3Wo7qFWdeMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/authentication.vue?macro=true";
import { default as index_46generated7EuRrvzbhXMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/index.generated.ts?macro=true";
import { default as indexCOw1GIDXdLMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/index.vue?macro=true";
import { default as rules1k2ueJyuylMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/rules.vue?macro=true";
import { default as index_46generatedO73BTSEQKOMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/index.generated.ts?macro=true";
import { default as indexrlWBw2dme8Meta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations/index.vue?macro=true";
import { default as integrationscS5cPJ1FP9Meta } from "/home/circleci/sleuth/frontend/pages/settings/organization/integrations.vue?macro=true";
import { default as resource_45allocationpOlvIkHTsnMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/resource-allocation.vue?macro=true";
import { default as usersjzXRZ8QEzRMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization/users.vue?macro=true";
import { default as organizationFPNHHzeb2kMeta } from "/home/circleci/sleuth/frontend/pages/settings/organization.vue?macro=true";
import { default as _91teamspaceId_93_46generatedQxlvRInMPmMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId].generated.ts?macro=true";
import { default as general_46generatedQtRUcciaa4Meta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/general.generated.ts?macro=true";
import { default as generalA216KmUc5FMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/general.vue?macro=true";
import { default as indexYRSkCyQfI7Meta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/integrations/[providerId]/index.vue?macro=true";
import { default as indexUn4wn9oEnmMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/integrations/index.vue?macro=true";
import { default as resource_45allocation_46generatedAU3MzF535SMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/resource-allocation.generated.ts?macro=true";
import { default as resource_45allocationBYwNUXONkCMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/resource-allocation.vue?macro=true";
import { default as teams_45and_45members_46generated3R0fgWrGkjMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/teams-and-members.generated.ts?macro=true";
import { default as teams_45and_45membersgK254pTHSDMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/teams-and-members.vue?macro=true";
import { default as _91teamspaceId_93mxq2aE02vtMeta } from "/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId].vue?macro=true";
import { default as generalJfL9FlnnhaMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/general.vue?macro=true";
import { default as my_46generatedAWfbH0XPIZMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/my.generated.ts?macro=true";
import { default as my1QH3oIRd2FMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/my.vue?macro=true";
import { default as notification_46generatedd6CKMonGEIMeta } from "/home/circleci/sleuth/frontend/pages/settings/user/notification.generated.ts?macro=true";
import { default as notificationYdv1Xyzmx7Meta } from "/home/circleci/sleuth/frontend/pages/settings/user/notification.vue?macro=true";
import { default as userZTZfneaqF9Meta } from "/home/circleci/sleuth/frontend/pages/settings/user.vue?macro=true";
import { default as sign_45invMkEx0OAvEMeta } from "/home/circleci/sleuth/frontend/pages/sign-in.vue?macro=true";
import { default as sign_45upnA4K3JYhW7Meta } from "/home/circleci/sleuth/frontend/pages/sign-up.vue?macro=true";
import { default as _91reviewId_931SBS3iGC0AMeta } from "/home/circleci/sleuth/frontend/pages/slides/review/[reviewId].vue?macro=true";
import { default as indexDsECDApeYAMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/index.vue?macro=true";
import { default as indexHVFiiNMOl1Meta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/reviews/[reviewId]/index.vue?macro=true";
import { default as indexZbSX6pecZkMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/reviews/index.vue?macro=true";
import { default as indexnwd7Y4q0MhMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/surveys/[surveyId]/index.vue?macro=true";
import { default as indexe4FKP19DGqMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/surveys/index.vue?macro=true";
import { default as index8mt48GuOrFMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/integrations/[providerId]/index.vue?macro=true";
import { default as indexz7MP6DzGriMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/integrations/index.vue?macro=true";
import { default as name_46generated4dQ8dgUYUhMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/name.generated.ts?macro=true";
import { default as nameCsA4XB01NTMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/name.vue?macro=true";
import { default as teams_45and_45membersAfbPJONurQMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new/teams-and-members.vue?macro=true";
import { default as new5QlWOsf31OMeta } from "/home/circleci/sleuth/frontend/pages/teamspace/new.vue?macro=true";
export default [
  {
    name: "delete-account",
    path: "/delete-account",
    component: () => import("/home/circleci/sleuth/frontend/pages/delete-account.vue")
  },
  {
    name: "index.generated",
    path: "/index.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/index.generated.ts")
  },
  {
    name: "index",
    path: "/",
    meta: indexh7SRb3nn6mMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/index.vue")
  },
  {
    name: "reset-password-uid-set-password",
    path: "/reset-password/:uid()/set-password",
    meta: set_45passwordAzscWNBrFJMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/reset-password/[uid]/set-password.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexvvru9fc4LzMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/reset-password/index.vue")
  },
  {
    name: "reset-password-request-success",
    path: "/reset-password/request-success",
    meta: request_45successNB3aNb4FiRMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/reset-password/request-success.vue")
  },
  {
    name: "reset-password-reset-success",
    path: "/reset-password/reset-success",
    meta: reset_45successVJaqBklhrAMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/reset-password/reset-success.vue")
  },
  {
    name: "settings.generated",
    path: "/settings.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings.generated.ts")
  },
  {
    name: "settings-organization.generated",
    path: "/settings/organization.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization.generated.ts")
  },
  {
    name: "settings-organization",
    path: "/settings/organization",
    meta: organizationFPNHHzeb2kMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization.vue"),
    children: [
  {
    name: "settings-organization-ai",
    path: "ai",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/ai.vue")
  },
  {
    name: "settings-organization-authentication",
    path: "authentication",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/authentication.vue")
  },
  {
    name: integrationscS5cPJ1FP9Meta?.name,
    path: "integrations",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations.vue"),
    children: [
  {
    name: "settings-organization-integrations-providerId-index.generated",
    path: ":providerId()/index.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/index.generated.ts")
  },
  {
    name: "settings-organization-integrations-providerId",
    path: ":providerId()",
    meta: indexCOw1GIDXdLMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/index.vue")
  },
  {
    name: "settings-organization-integrations-providerId-rules",
    path: ":providerId()/rules",
    meta: rules1k2ueJyuylMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/[providerId]/rules.vue")
  },
  {
    name: "settings-organization-integrations-index.generated",
    path: "index.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/index.generated.ts")
  },
  {
    name: "settings-organization-integrations",
    path: "",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/integrations/index.vue")
  }
]
  },
  {
    name: "settings-organization-resource-allocation",
    path: "resource-allocation",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/resource-allocation.vue")
  },
  {
    name: "settings-organization-users",
    path: "users",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/organization/users.vue")
  }
]
  },
  {
    name: "settings-teamspace-teamspaceId.generated",
    path: "/settings/teamspace/:teamspaceId().generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId].generated.ts")
  },
  {
    name: "settings-teamspace-teamspaceId",
    path: "/settings/teamspace/:teamspaceId()",
    meta: _91teamspaceId_93mxq2aE02vtMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId].vue"),
    children: [
  {
    name: "settings-teamspace-teamspaceId-general.generated",
    path: "general.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/general.generated.ts")
  },
  {
    name: "settings-teamspace-teamspaceId-general",
    path: "general",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/general.vue")
  },
  {
    name: "settings-teamspace-teamspaceId-integrations-providerId",
    path: "integrations/:providerId()",
    meta: indexYRSkCyQfI7Meta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/integrations/[providerId]/index.vue")
  },
  {
    name: "settings-teamspace-teamspaceId-integrations",
    path: "integrations",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/integrations/index.vue")
  },
  {
    name: "settings-teamspace-teamspaceId-resource-allocation.generated",
    path: "resource-allocation.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/resource-allocation.generated.ts")
  },
  {
    name: "settings-teamspace-teamspaceId-resource-allocation",
    path: "resource-allocation",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/resource-allocation.vue")
  },
  {
    name: "settings-teamspace-teamspaceId-teams-and-members.generated",
    path: "teams-and-members.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/teams-and-members.generated.ts")
  },
  {
    name: "settings-teamspace-teamspaceId-teams-and-members",
    path: "teams-and-members",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/teamspace/[teamspaceId]/teams-and-members.vue")
  }
]
  },
  {
    name: "settings-user",
    path: "/settings/user",
    meta: userZTZfneaqF9Meta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user.vue"),
    children: [
  {
    name: "settings-user-general",
    path: "general",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/general.vue")
  },
  {
    name: "settings-user-my.generated",
    path: "my.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/my.generated.ts")
  },
  {
    name: "settings-user-my",
    path: "my",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/my.vue")
  },
  {
    name: "settings-user-notification.generated",
    path: "notification.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/notification.generated.ts")
  },
  {
    name: "settings-user-notification",
    path: "notification",
    component: () => import("/home/circleci/sleuth/frontend/pages/settings/user/notification.vue")
  }
]
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45invMkEx0OAvEMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upnA4K3JYhW7Meta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/sign-up.vue")
  },
  {
    name: "slides-review-reviewId",
    path: "/slides/review/:reviewId()",
    meta: _91reviewId_931SBS3iGC0AMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/slides/review/[reviewId].vue")
  },
  {
    name: "teamspace-workspaceId",
    path: "/teamspace/:workspaceId()",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/index.vue")
  },
  {
    name: "teamspace-workspaceId-reviews-reviewId",
    path: "/teamspace/:workspaceId()/reviews/:reviewId()",
    meta: indexHVFiiNMOl1Meta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/reviews/[reviewId]/index.vue")
  },
  {
    name: "teamspace-workspaceId-reviews",
    path: "/teamspace/:workspaceId()/reviews",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/reviews/index.vue")
  },
  {
    name: "teamspace-workspaceId-surveys-surveyId",
    path: "/teamspace/:workspaceId()/surveys/:surveyId()",
    meta: indexnwd7Y4q0MhMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/surveys/[surveyId]/index.vue")
  },
  {
    name: "teamspace-workspaceId-surveys",
    path: "/teamspace/:workspaceId()/surveys",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/[workspaceId]/surveys/index.vue")
  },
  {
    name: "teamspace-new",
    path: "/teamspace/new",
    meta: new5QlWOsf31OMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new.vue"),
    children: [
  {
    name: "teamspace-new-integrations-providerId",
    path: "integrations/:providerId()",
    meta: index8mt48GuOrFMeta || {},
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/integrations/[providerId]/index.vue")
  },
  {
    name: "teamspace-new-integrations",
    path: "integrations",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/integrations/index.vue")
  },
  {
    name: "teamspace-new-name.generated",
    path: "name.generated",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/name.generated.ts")
  },
  {
    name: "teamspace-new-name",
    path: "name",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/name.vue")
  },
  {
    name: "teamspace-new-teams-and-members",
    path: "teams-and-members",
    component: () => import("/home/circleci/sleuth/frontend/pages/teamspace/new/teams-and-members.vue")
  }
]
  }
]