import type { WsEventType } from '~/codegen/graphql'

interface WSEvent {
  on: string
  type: WsEventType
  objectId: string
  parentId: string
  actorId?: string
}

interface EventHandler {
  events: WsEventType[]
  matcher: (data: WSEvent) => boolean
  cb: () => void
}

const currentUserId = ref('')
const eventHandlers = ref<EventHandler[]>([])

export default function () {
  const { $ws } = useNuxtApp()

  function executeCallbacks(wsData: string) {
    const data = JSON.parse(wsData)
    for (const { events, matcher, cb } of eventHandlers.value) {
      if (events.includes(data.type) && matcher(data)) {
        if (data.actorId && data.actorId === currentUserId.value) {
          // skip event handling triggered from the current user
          return
        }
        cb()
      }
    }
  }

  function startWatching(userId: string) {
    $ws.open()
    watch($ws.data, executeCallbacks)
    currentUserId.value = userId
  }

  function registerWSEventHandler(eventHandler: EventHandler) {
    // only add when not already registered
    if (eventHandlers.value.find(eh => eh.events.every(e => eventHandler.events.includes(e))))
      return
    eventHandlers.value.push(eventHandler)
  }

  return { startWatching, registerWSEventHandler }
}
